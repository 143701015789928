<template>
  <b-modal
    id="reject-withdraw-modal"
    :title="`${$t('utils.status.reject')} - ${$t('routes.VIEW_TRANSACTION_REQUEST')}`"
    hide-footer
  >
    <validation-observer
      ref="rejectWithdrawForm"
    >
      <form @submit.prevent="onSubmit">
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label-for="isRefund"
        >
          <b-input-group>
            <b-form-checkbox
              v-model="rejectForm.isRefund"
              name="isRefund"
            >
              คืนเงิน
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
        <validation-provider
          v-slot="{ errors }"
          name="remark"
          :rules="!rejectForm.isRefund ? 'required' : null"
        >
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="`หมายเหตุ ${!rejectForm.isRefund?'':'*'}`"
            label-for="remark"
          >
            <b-form-input
              id="remark"
              v-model="rejectForm.remark"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
      
        <div class="text-right">
          <b-button
            variant="light"
            class="mr-2"
            @click="$bvModal.hide('eject-withdraw-modal')"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="isLoading"
            rounded
            spinner-small
            class="d-inline-block"
          >
            <b-button
              type="submit"
              variant="primary"
              block
            >
              {{ $t('buttons.confirm') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RejectWithdrawModal',
  props: {
    txnId: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      required,

      rejectForm: {
        isRefund: true,
        remark: '',
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.withdraw.isRejectingWithdraw,
    }),
  },
  methods: {
    ...mapActions(['rejectWithdraw']),
    closeModal() {
      this.$bvModal.hide('reject-withdraw-modal')
      this.rejectForm = {
        isRefund: true,
        remark: '',
      }
    },
    async onSubmit() {
      this.$refs.rejectWithdrawForm.validate().then( async (success) => {
        if (success) {
          await this.rejectWithdraw({
            id: this.txnId, 
            data: this.rejectForm
          })
          this.closeModal()
        }
      })
      
    },
  },
}
</script>
